:global(#app) {
  .field {
    margin-bottom: 8px;
  }

  .note {
    font-size: 11px;
    margin-top: 4px;
    opacity: 0.5;
  }

  .text {
    color: #444444;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 6px;
  }
}
